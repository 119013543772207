var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    ref: "modalViewWitness",
    attrs: {
      "centered": "",
      "title": _vm.$t('witnessmodal.desc'),
      "ok-title": _vm.$t('close'),
      "ok-only": "",
      "no-enforce-focus": true
    },
    scopedSlots: _vm._u([_vm.isDeletable || _vm.isEditable ? {
      key: "modal-footer",
      fn: function () {
        return [_c('div', {
          staticClass: "w-100"
        }, [_vm.isDeletable ? _c('button', {
          staticClass: "btn btn-danger float-left",
          on: {
            "click": _vm.removeWitness
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("remove")) + " ")]) : _vm._e(), _vm.isEditable ? _c('button', {
          staticClass: "btn btn-primary float-right",
          on: {
            "click": _vm.updateWitness
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("save")) + " ")]) : _vm._e()])];
      },
      proxy: true
    } : null], null, true)
  }, [_vm.witness ? _c('div', [_c('div', {
    staticClass: "form-group row",
    class: {
      'has-error': _vm.$v.witness.name.$error
    }
  }, [_c('label', {
    staticClass: "col-sm-3 col-form-label"
  }, [_vm._v(_vm._s(_vm.$t("name")))]), _c('div', {
    staticClass: "col-sm-9"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.witness.name.$model,
      expression: "$v.witness.name.$model",
      modifiers: {
        "trim": true
      }
    }],
    class: _vm.isEditable ? 'form-control' : 'form-control-plaintext',
    attrs: {
      "type": "text",
      "readonly": !_vm.isEditable
    },
    domProps: {
      "value": _vm.$v.witness.name.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.witness.name, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.witness.name.$error && !_vm.$v.witness.name.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.name")) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "form-group row",
    class: {
      'has-error': _vm.$v.witness.ic_number.$error
    }
  }, [_c('label', {
    staticClass: "col-sm-3 col-form-label",
    attrs: {
      "for": "ic_number"
    }
  }, [_vm._v(_vm._s(_vm.$t("mykad")))]), _c('div', {
    staticClass: "col-sm-9"
  }, [_c('the-mask', {
    class: _vm.isEditable ? 'form-control' : 'form-control-plaintext',
    attrs: {
      "mask": ['######-##-####'],
      "readonly": !_vm.isEditable,
      "id": "ic_number"
    },
    model: {
      value: _vm.$v.witness.ic_number.$model,
      callback: function ($$v) {
        _vm.$set(_vm.$v.witness.ic_number, "$model", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "$v.witness.ic_number.$model"
    }
  }), _vm.$v.witness.ic_number.$error && !_vm.$v.witness.ic_number.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.mykad")) + " ")]) : _vm._e()], 1)]), _c('div', {
    staticClass: "form-group row",
    class: {
      'has-error': _vm.$v.witness.email.$error
    }
  }, [_c('label', {
    staticClass: "col-sm-3 col-form-label",
    attrs: {
      "for": "email"
    }
  }, [_vm._v(_vm._s(_vm.$t("email")))]), _c('div', {
    staticClass: "col-sm-9"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.witness.email.$model,
      expression: "$v.witness.email.$model",
      modifiers: {
        "trim": true
      }
    }],
    class: _vm.isEditable ? 'form-control' : 'form-control-plaintext',
    attrs: {
      "readonly": !_vm.isEditable,
      "id": "email"
    },
    domProps: {
      "value": _vm.$v.witness.email.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.witness.email, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.witness.email.$error && !_vm.$v.witness.email.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.email")) + " ")]) : _vm._e()])]), _vm.isEditable ? _c('div', [_c('div', {
    staticClass: "form-group row",
    class: {
      'has-error': _vm.$v.witness.address.$error
    }
  }, [_c('label', {
    staticClass: "col-sm-3 col-form-label",
    attrs: {
      "for": "address"
    }
  }, [_vm._v(_vm._s(_vm.$t("address")))]), _c('div', {
    staticClass: "col-sm-9"
  }, [_c('textarea', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.witness.address.$model,
      expression: "$v.witness.address.$model",
      modifiers: {
        "trim": true
      }
    }],
    class: _vm.isEditable ? 'form-control' : 'form-control-plaintext',
    attrs: {
      "type": "text",
      "id": "address",
      "readonly": !_vm.isEditable
    },
    domProps: {
      "value": _vm.$v.witness.address.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.witness.address, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.witness.address.$error && !_vm.$v.witness.address.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("address")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "form-group row",
    class: {
      'has-error': _vm.$v.witness.postcode.$error
    }
  }, [_c('label', {
    staticClass: "col-sm-3 col-form-label",
    attrs: {
      "for": "postcode"
    }
  }, [_vm._v(_vm._s(_vm.$t("postcode")))]), _c('div', {
    staticClass: "col-sm-4"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.lazy.trim",
      value: _vm.$v.witness.postcode.$model,
      expression: "$v.witness.postcode.$model",
      modifiers: {
        "lazy": true,
        "trim": true
      }
    }],
    class: _vm.isEditable ? 'form-control' : 'form-control-plaintext',
    attrs: {
      "type": "number",
      "id": "postcode",
      "readonly": !_vm.isEditable
    },
    domProps: {
      "value": _vm.$v.witness.postcode.$model
    },
    on: {
      "blur": [_vm.getPostcode, function ($event) {
        return _vm.$forceUpdate();
      }],
      "change": function ($event) {
        _vm.$set(_vm.$v.witness.postcode, "$model", $event.target.value.trim());
      }
    }
  }), _vm.$v.witness.postcode.$error && !_vm.$v.witness.postcode.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.postcode")) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-3 col-form-label",
    attrs: {
      "for": "city"
    }
  }, [_vm._v(_vm._s(_vm.$t("city")))]), _c('div', {
    staticClass: "col-sm-4"
  }, [_c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.witness.city,
      expression: "witness.city"
    }],
    class: _vm.isEditable ? 'form-control' : 'form-control-plaintext',
    attrs: {
      "type": "text",
      "readonly": "",
      "id": "city"
    },
    domProps: {
      "value": _vm.witness.city
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.witness, "city", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-3 col-form-label",
    attrs: {
      "for": "state"
    }
  }, [_vm._v(_vm._s(_vm.$t("state")))]), _c('div', {
    staticClass: "col-sm-4"
  }, [_c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.witness.state,
      expression: "witness.state"
    }],
    class: _vm.isEditable ? 'form-control' : 'form-control-plaintext',
    attrs: {
      "type": "text",
      "readonly": "",
      "id": "state"
    },
    domProps: {
      "value": _vm.witness.state
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.witness, "state", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-3 col-form-label",
    attrs: {
      "for": "signature"
    }
  }, [_vm._v(_vm._s(_vm.$t("profile.signature")))]), _c('div', {
    staticClass: "col-sm-7"
  }, [_c('img', {
    staticStyle: {
      "width": "100%",
      "border": "1px solid #ddd",
      "border-radius": "15px"
    },
    attrs: {
      "src": _vm.signature,
      "alt": ""
    }
  })])])]) : _c('div', [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-3 col-form-label",
    attrs: {
      "for": "state"
    }
  }, [_vm._v(_vm._s(_vm.$t("address")))]), _c('div', {
    staticClass: "col-sm-9"
  }, [_c('p', {
    staticClass: "form-control-plaintext"
  }, [_vm._v(_vm._s(_vm.fullAddress))])])])])]) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }